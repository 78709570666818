<template>
  <div id="cms">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>

export default {
  data: () => ({
  }),
  computed: {
  },
};
</script>

<style lang="scss" scoped>
#cms {
  background: #F6F8FF;
}
</style>
